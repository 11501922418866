import React, { useContext, useEffect, useState } from "react";
import Styled from "styled-components";
import { Empreendimento, Imóvel } from "smart-imob-types";
import { toBRL } from "../../utils/util_functions";
import { Link } from "react-router-dom";
import EmpresaContext from "../../context";
import locations from "../../utils/localizacoes";

interface CidadeType {
  nome: string | null;
  id: number | null;
}

const CondominiumCardStyle = Styled.div`
  margin: 30px 15px;
  .card-container {
    position: relative;
    border-radius: 15px;
    display: grid;
    grid-template-rows: 1fr auto;
    overflow: hidden;
    box-shadow: ${props =>
      props.empresa.palleta.cor_primaria + "20"} 0px 20px 25px -5px, ${props =>
  props.empresa.palleta.cor_primaria + "05"} 0px 10px 10px -5px;
    /* box-shadow: ${props =>
      props.empresa.palleta.cor_primaria + "20"} 0px 3px 8px; */
    picture {
      position: relative;
      img {
        min-width: 100%;
        /* max-height: 280px; */
        height: 280px;
        object-fit: cover;
        transition: 250ms ease;
      }
      .property-code {
        position: absolute;
        background: ${props => props.empresa.palleta.cor_primaria};
        color: #fff;
        padding: 5px 10px;
        border-radius: 4px;
        top: 20px;
        left: 20px;
        z-index: 1;
      }
    }
    .info-container {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      width: 100%;
      padding: 20px;
      color: #1a1a1a;
      background: #fff;
      z-index: 1;
      /* min-height: 340px; */
      .property-location {
        color: ${props => props.empresa.palleta.cor_primaria};
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        font-size: 15px;
        font-weight: 700;
        i {
          margin-right: 5px;
        }
      }
      .property-title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 17px;
        color: #282828;
      }
      .property-price {
        color: ${props => props.empresa.palleta.cor_primaria};
        margin-bottom: 20px;
        font-weight: 700;
      }
      .info-list {
        display: flex;
        width: 100%;
        font-size: 12px;
        border-top: 1px solid ${props => props.empresa.palleta.cor_primaria};
        .single-info {
          padding: 20px 0;
          margin-right: 10px;
        }
      }
      .btn-property {
        background: ${props => props.empresa.palleta.cor_primaria};
        color: #fff;
        padding: 15px 20px;
        border-radius: 25px;
        margin-top: 5px;
        height: auto;
        line-height: normal;
      }
    }
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
`;

interface CondominiumCardProps {
  empreendimento: Empreendimento;
}

const CondominiumCard: React.FC<CondominiumCardProps> = props => {
  const context = useContext(EmpresaContext);
  const { empreendimento } = props;
  let imgSrc;
  let imgSrcWebp;
  try {
    const fotoDestaque = empreendimento.fotos.find(e => e.destaque === true);
    imgSrcWebp = fotoDestaque ? fotoDestaque.source.uri_webp || "" : "";
    imgSrc = fotoDestaque
      ? fotoDestaque.source.uri
      : empreendimento.fotos[0].source.uri;
  } catch (_) {
    imgSrc =
      "https://firebasestorage.googleapis.com/v0/b/smartimob-dev-test.appspot.com/o/placeholders%2Fproperty-placeholder.png?alt=media&token=96ef2786-f47a-484c-aa76-82880bd20b01";
  }
  let link = `/empreendimento/${empreendimento.db_id}`;

  const [cidade, setCidade] = useState<CidadeType>({ nome: "", id: null });
  useEffect(() => {
    if (
      empreendimento.cidade_id &&
      locations.cidades[empreendimento.cidade_id]
    ) {
      const cidade = {
        nome: locations.cidades[empreendimento.cidade_id][0],
        id: Number(empreendimento.cidade_id)
      };
      setCidade(cidade);
    }
  }, []);

  return (
    <CondominiumCardStyle empresa={context}>
      <Link to={link}>
        <a>
          <div className="card-container">
            <picture>
              <div className="property-code">{empreendimento.referencia}</div>
              <source srcSet={imgSrcWebp} type="image/webp" />
              <source srcSet={imgSrc} type="image/jpeg" />
              <img
                src={imgSrc}
                alt={
                  empreendimento.referencia
                    ? empreendimento.referencia.toString()
                    : "empreendimento"
                }
              />
            </picture>
            <div className="info-container">
              <div className="property-location">
                <i className="fa fa-map-marker" />
                {empreendimento.bairro && cidade.nome
                  ? empreendimento.bairro + ", " + cidade.nome
                  : cidade.nome
                    ? cidade.nome
                    : empreendimento.bairro
                      ? empreendimento.bairro
                      : null}
              </div>
              <div className="property-title">{empreendimento.nome}</div>
              <ul className="info-list">
                {!!(
                  empreendimento.dormitorios &&
                  empreendimento.dormitorios !== "0"
                ) && (
                  <li className="single-info">
                    <i className="fa fa-bed" />
                    {" " + empreendimento.dormitorios} Dormitórios
                  </li>
                )}
                {!!(
                  empreendimento.banheiros && empreendimento.banheiros !== "0"
                ) && (
                  <li className="single-info">
                    <i className="fa fa-bath" />
                    {" " + empreendimento.banheiros} Banheiros
                  </li>
                )}
                {empreendimento.situacao && (
                  <li className="single-info">
                    <i className="fa fa-calendar" />
                    {" " + empreendimento.situacao}
                  </li>
                )}
                {empreendimento.finalidade && (
                  <li className="single-info">
                    <i className="fa fa-home" />
                    {" " + empreendimento.finalidade}
                  </li>
                )}
              </ul>
              <Link to={link} className="btn-property btn btn-yellow">
                Ver mais detalhes
              </Link>
            </div>
          </div>
        </a>
      </Link>
    </CondominiumCardStyle>
  );
};

export default CondominiumCard;
