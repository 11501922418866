import React, { PureComponent } from "react";
import styled from "styled-components";
import { Empreendimento, Empresa, Imóvel } from "smart-imob-types";
import Slider from "react-slick";
import CondominiumCard from "./condominium-card-v3";
import Loading from "../global-components/Loading";
import EmpresaContext from "../../context";
import { post } from "../../client_api";
import { HBT } from "../../builder-context";

interface RecentCondominiumStyleProps {
  empresa: Empresa;
}

const RecentCondominiumStyle = styled.div<RecentCondominiumStyleProps>`
  max-width: 90%;
  padding-top: 70px;
  margin: 0 auto;
  .title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    flex-direction: column;
    .text-container {
      .featured-property-title {
        margin-bottom: 20px;
      }
      .featured-property-subtitle {
      }
    }
    .slider-controls {
      display: flex;
      .btn-prev,
      .btn-next {
        font-size: 26px;
        padding: 0px 25px;
        border-radius: 25px;
        transition: 250ms ease;
        cursor: pointer;
        &:hover {
          color: #fff;
          background: ${props => props.empresa.palleta.cor_primaria};
          box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
        }
      }
      .btn-prev {
        margin-right: 20px;
      }
    }
  }
  .featured-property-container {
    margin: 0 -15px;
  }
  @media (min-width: 576px) {
    max-width: 540px;
    .title-container {
      flex-direction: row;
    }
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
  @media (min-width: 1600px) {
    max-width: 1360px;
  }
`;

interface RecentCondominiumProps {
  empresa?: Empresa;
}

interface RecentCondominiumState {
  empreendimentos: Empreendimento[];
  loading: boolean;
  slider: any;
}

class RecentCondominium extends PureComponent<
  RecentCondominiumProps,
  RecentCondominiumState
> {
  private sliderLancamentos: React.RefObject<HTMLInputElement>;
  state = {
    empreendimentos: [],
    loading: true,
    slider: null
  };

  constructor(props) {
    super(props);
    this.sliderLancamentos = React.createRef();
  }

  async componentDidMount() {
    this.setState({
      slider: this.sliderLancamentos
    });
    try {
      let empreendimentos = await post(
        this.context.url,
        "buscar_empreendimentos",
        {
          filtros: {
            situacao: "Em construção"
          },
          limit: 10
        }
      );
      this.setState(
        {
          empreendimentos: empreendimentos,
          loading: false
        },
        () =>
          console.log(
            "empreendimentos lancamentos: ",
            this.state.empreendimentos
          )
      );
    } catch (error) {
      console.log("não pegou empreendimentos lançamentos", error);
    }
  }

  render() {
    const empresa = this.context;
    const { slider, empreendimentos } = this.state;
    const prev = () => {
      // @ts-ignore
      this.sliderLancamentos.slickPrev();
    };
    const next = () => {
      // @ts-ignore
      this.sliderLancamentos.slickNext();
    };
    const settings = {
      infinite: this.state.empreendimentos.length > 2 ? true : false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      lazyLoad: "progressive",
      arrows: false,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            infinite: this.state.empreendimentos.length > 1 ? true : false,
            slidesToShow: 2
          }
        },
        {
          breakpoint: 560,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    };
    if (this.state.loading) {
      return <Loading />;
    }
    if (this.state.empreendimentos.length === 0) {
      return null;
    }
    return (
      <RecentCondominiumStyle empresa={empresa}>
        <div className="title-container">
          <div className="text-container">
            <h2
              className="recent-condominium-title"
              {...HBT("bb_recent_condominium_title")}
            >
              {this.context["bb_recent_condominium_title"] ||
                "Empreendimentos Recentes"}
            </h2>
            <p
              className="recent-condominium-subtitle"
              {...HBT("bb_recent_condominium_subtitle")}
            >
              {this.context["bb_recent_condominium_subtitle"] ||
                "Veja nossos empreendimentos mais recentes"}
            </p>
          </div>
          <div className="slider-controls">
            <span className="btn-prev" onClick={() => prev()}>
              <i className="fa fa-arrow-left" />
            </span>
            <span className="btn-next" onClick={() => next()}>
              <i className="fa fa-arrow-right" />
            </span>
          </div>
        </div>
        <div className="recent-condominium-container">
          <Slider
            ref={slider => (this.sliderLancamentos = slider)}
            {...settings}
          >
            {empreendimentos.map((empreendimento: Empreendimento) => (
              <CondominiumCard
                key={empreendimento.referencia}
                empreendimento={empreendimento}
              />
            ))}
          </Slider>
        </div>
      </RecentCondominiumStyle>
    );
  }
}

RecentCondominium.contextType = EmpresaContext;
export default RecentCondominium;
